export const helpToggle = () => {
	[].forEach.call(document.querySelectorAll('.item_header'), (el) => {
		el.addEventListener('click', (e) => {
			let header = e.currentTarget;
			header.classList.toggle('open');
			header.nextElementSibling.classList.toggle('open');
		});
	});
    
	setTimeout(() => {
		document.querySelector('#help_list .item_header').click();
	}, 800);
};