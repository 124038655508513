import { contactSubmit } from './contact.js';
import { mobileNavToggle } from './header.js';
import { helpToggle } from './help.js';
import { AutomatitCarousel } from './automatit_carousel.m';
import { initMap } from './map';
import { initializeFacilityCarousel, filterUnits, showFacInfo } from './facility';

mobileNavToggle();

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('help_list')) {
	helpToggle();
}

if (document.getElementById('map')) {
	initMap();
}

if (document.querySelector('#facility_page')) {
	initializeFacilityCarousel();
	filterUnits();
	showFacInfo();
}
if (document.getElementById('index_caro')) {
	const options = {
		element: document.getElementById('index_caro'),
		speed: 0,
		intervalTiming: 0,
		imagesAsBackgrounds: true,
		showDots: false,
		useChevrons: false,
	};
	AutomatitCarousel(options);
}
