import { AutomatitCarousel } from './automatit_carousel.m';

export const initializeFacilityCarousel = () => {
	const options = {
		element: document.querySelector('#fac_caro'),
		images: window.facCaroImgs,
		imagesAsBackgrounds: true,
		showDots: false,
		autoVerticalCentering: false,
		useChevrons: true,
	};
    
	if(window.facCaroImgs.length === 1) {
		options['intervalTiming'] = false;
		options['useChevrons'] = false;
	}
	AutomatitCarousel(options);
};

export const filterUnits = () => {
	const filters = document.querySelectorAll('#filters .filter');
	const units = document.querySelectorAll('#rates .unit_row');
	[].forEach.call(filters, (filter) => {
		filter.addEventListener('click', (e) => {
			let filter = e.currentTarget;
			[].forEach.call(filters, (el) => {
				el.classList.remove('active');
			});
			filter.classList.add('active');
            
			let visibleUnits = 0;
			[].forEach.call(units, (unit) => {
				unit.classList.add('hidden');
				if(unit.dataset.size === filter.dataset.size || filter.dataset.size === 'all'){
					unit.classList.remove('hidden');
					visibleUnits++;
				}
			});
            
			if(visibleUnits > 0){
				document.getElementById('no_units').style.display = 'none';
			}else{
				document.getElementById('no_units').style.display = 'block';
			}
		});
	});
};

export const showFacInfo = () => {
	document.getElementById('fac_info_toggle').addEventListener('click', (e) => {
		let button = e.currentTarget;
		button.classList.toggle('active');
		button.nextElementSibling.classList.toggle('active');
	});
};